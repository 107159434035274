<template>
  <div class="wrapper">
    <ul
      style="margin:0;"
      class="uk-breadcrumb"
    >
      <li
        v-for="(item, i) in BreadCrumb"
        :key="i"
      >
        <router-link
          v-if="item.link"
          :to="item.link"
        >
          {{ item.title }}
        </router-link>
        <!-- <a v-if="item.link" :href="item.link"> {{ item.title }} </a> -->
        <span v-else> {{ item.title }} </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    BreadCrumb: {
      required: true,
      type: Array
    }
  }
}
</script>

<style scoped>
.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: normal;
    font-size: 14px;
    color: #025231;
}
a {
  text-decoration: none;
  font-size: 14px;
  line-height: 150%;
  color: #025231;
}
</style>
