<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default uk-padding-small kandang-card container-size">
      <div class="uk-form-horizontal">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Package Name
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input 
              v-model="settingChoose.package_name"
              v-validate="'required'"
              type="text"  
              name="name"
              class="uk-input"
            >
            <div
              v-show="errors.has('name')"
              class="uk-text-small uk-text-danger"
            >
              {{ errors.first('name') }}
            </div>
          </div>
        </div>
        <div
          class="filter-margin uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Version Code
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <input 
              v-model="settingChoose.version_code"
              v-validate="'required'" 
              type="text"
              class="uk-input"
              name="name"
            >
            <div
              v-show="errors.has('name')"
              class="uk-text-small uk-text-danger"
            >
              {{ errors.first('name') }}
            </div>
          </div>
        </div>
        <div
          class="filter-margin uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
            Force Update
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
            <div class="uk-grid">
              <div class="uk-width-1-3">
                <input
                  v-model="settingChoose.force_update"
                  class="switcher"
                  type="checkbox"
                >
              </div>
              <div class="uk-width-2-3 status-margin">
                <span
                  v-if="settingChoose.force_update"
                  class="uk-text-success label-unbackground"
                >Ya</span>
                <span
                  v-else
                  class="uk-text-danger label-unbackground"
                >Tidak</span>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-text-right uk-margin-top">
          <button
            class="uk-button uk-button-danger"
            type="button"
            @click="goToBack"
          >
            <img
              v-lazy="`${images}/icon/close.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Batal
          </button>
          <button
            class="uk-button uk-button-primary uk-margin-left"
            type="button"
            @click="showSaveConfirmModal"
          >
            <img
              v-lazy="`${images}/icon/save.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Simpan
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant'
import {mapActions, mapGetters,mapMutations} from 'vuex'
export default {
  props: {
    dataSetting: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      forceupdateSwitch: true,
      settingChoose:{}
    }
  },
  computed : {
    ...mapGetters({
      dataSetting: 'setting/setting'
    })
  },
  watch: {
    async dataSetting () {
      this.settingChoose = {
        id: this.dataSetting.id,
        package_name: this.dataSetting.package_name,
        force_update: this.dataSetting.force_update,
        version_code: this.dataSetting.version_code
      }
    }
  },
  async mounted() {
    await this.getSetting()
    this.is_loading = false
  },
  methods: {
    ...mapMutations({
      setsettingEdit: 'setting/SET_SETTING_EDIT'
    }),
    ...mapActions({
      getSetting: 'setting/getSetting',
      editSetting: 'setting/editSetting'
    }),
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.$validator.validateAll().then((success) =>{
        if (success) {
          if(this.$validator.errors.any()) return
          window.UIkit.modal('#modal-save-confirm').show()
          this.setsettingEdit(this.settingChoose)
        }
      })
    }
  }
}
</script>

<style scoped>
.button-margin {
  margin-top: 30px;
}

.status-margin {
  padding-left: 5px !important;
  padding-top: 10px;
}

.container-size {
  height: 100%;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .container-size {
    height: 100%;
    width: 550px;
  }
}
</style>
