<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Edit Settings
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container />
    <modal-out-confirm />
    <modal-save-confirm />
  </div>
</template>

<script>
import Container from './Container'
import ModalOutConfirm from './ModalOutConfirm'
import ModalSaveConfirm from './ModalSaveConfirm'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    ModalOutConfirm,
    ModalSaveConfirm,
    BreadCrumb
  },
  data() {
    return {
      breadcrumb: [
        {
          link: '/admin/settings',
          title: 'Settings'
        },
        {
          link: null,
          title: 'Edit Settings'
        }
      ]
    }
  }
}
</script>
